.m-sectitle {
  text-align: center;
  .en {
    color: $color-main;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.0;
    font-family: $font-en;
    @include media-up(md) {
      font-size: 24px;
    }
  }
  .ja {
    font-size: 18px;
    font-weight: bold;
    margin-top: 12px;
    padding-bottom: 22px;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 95px;
      height: 0;
      border-top: solid 5px $color-main;
      position: absolute;
      bottom: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    @include media-up(md) {
      font-size: 32px;
      margin-top: 20px;
      padding-bottom: 30px;
      .sp {
        display: none;
      }
    }
  }
}

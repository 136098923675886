.footer {
  .footer-content {
    padding: 35px 0 30px;
    @include media-up(md) {
      padding: 55px 0 30px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .footer-content__info {
      .logo {
        width: 165px;
        margin: 0 auto;
        @include media-up(md) {
          width: 215px;
          margin: 0;
        }
      }
      .name {
        margin: 20px 0 15px;
        font-size: 16px;
        text-align: center;
        @include media-up(md) {
          text-align: left;
        }
      }
      .text,a {
        font-size: 16px;
        text-align: center;
        @include media-up(md) {
          text-align: left;
        }
      }
    }
    .footer-content__sns {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin: 35px -16px 0;
      @include media-up(md) {
        margin: 0;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
      }
      .item {
        width: 55px;
        height: 55px;
        margin: 0 8px;
        @include media-up(md) {
          margin: 0 0 0 20px;
        }
        a {
          width: 100%;
          height: 100%;
          background: $color-main;
          border-radius: 50%;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          color: $color-white;
          border: solid 1px $color-main;
          @include media-up(md) {
            &:hover {
              background: $color-white;
              color: $color-main;
            }
          }
        }
        .fab {
          font-size: 20px;
        }
      }
    }
  }
  .copyright {
    margin: 10px 0;
    text-align: center;
    font-size: 12px;
    @include media-up(md) {
      margin: 25px 0 30px;
    }
  }
  .fixed-btn {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    a {
      display: block;
      width: 100%;
    }
  }
}
.footer.index-footer {
  padding-bottom: 140px;
  @include media-up(md) {
    padding-bottom: 0;
  }
}

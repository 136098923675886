#index {
  .mainvis {
    position: relative;
    margin-top: 66px;
    @include media-up(md) {
      margin-top: 80px;
      height: 100vh;
      min-height: 600px;
      max-height: 750px;
      width: 100%;
      background: url(../img/mainvis_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    @include media-up(lg) {
      margin-top: 0;
    }
    .l-container {
      position: absolute;
      bottom: 30px;
      left: 0;
      @include media-up(md) {
        position: relative;
        height: 100%;
        bottom: 0;
      }
    }
    .mainvis-content {
      @include media-up(md) {
        background: rgba(56,56,56,0.55);
        width: 480px;
        height: 100%;
        position: relative;
      }
      .inner {
        @include media-up(md) {
          position: absolute;
          width: 100%;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
      .lead {
        text-align: center;
        font-size: 5.7vw;
        font-weight: bold;
        color: $color-white;
        .pc {
          display: none;
        }
        @include media-up(sm) {
          font-size: 31px;
        }
        @include media-up(md) {
          font-size: 40px;
          .pc {
            display: inline;
          }
        }
      }
      .sub {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: $color-white;
        margin-top: 30px;
        @include media-up(sm) {
          font-size: 20px;
        }
        @include media-up(md) {
          font-size: 22px;
          margin-top: 70px;
        }
      }
      .btn {
        margin-top: 30px;
        @include media-up(md) {
          margin-top: 70px;
        }
        a {
          display: block;
          width: 100%;
          max-width: 280px;
          margin: 0 auto;
          height: 55px;
          line-height: 55px;
          text-align: center;
          color: $color-white;
          font-size: 14px;
          font-weight: bold;
          border-radius: 45px;
          overflow: hidden;
          position: relative;
          background: -webkit-gradient(linear,left top, right top,from(#F5A13F), to(#DD533A));
          background: -webkit-linear-gradient(left,#F5A13F, #DD533A);
          background: -o-linear-gradient(left,#F5A13F, #DD533A);
          background: linear-gradient(to right,#F5A13F, #DD533A);
          span {
            width: 100%;
            display: block;
            position: relative;
            z-index: 2;
            &::before {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6.5px 0 6.5px 14px;
              border-color: transparent transparent transparent $color-white;
              position: absolute;
              right: 25px;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }
          @include media-up(md) {
            max-width: 350px;
            font-size: 18px;
            height: 77px;
            line-height: 77px;
            &:hover {
              -webkit-box-shadow: 0 5px 5px rgba(0,0,0,0.2);
              box-shadow: 0 5px 5px rgba(0,0,0,0.2);
            }
          }

        }
      }
    }
    .mainvis-image {
      @include media-up(md) {
        display: none;
      }
    }
  }
  .about {
    padding: 101px 0 35px;
    margin-top: -66px;
    @include media-up(md) {
      padding: 190px 0 110px;
      margin-top: -80px;
    }
    @include media-up(lg) {
      padding: 110px 0;
      margin-top: 0;
    }
    .about-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin-top: 30px;
      @include media-up(md) {
        margin-top: 45px;
      }
      .about-content__image {
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        @include media-up(md) {
          margin: 0;
          width: 50%;
        }
      }
      .about-content__text {
        width: 100%;
        margin-top: 20px;
        @include media-up(md) {
          width: 50%;
          margin-top: 0;
          padding-left: 30px;
          p {
            font-size: 16px;
          }
        }
        @include media-up(lg) {
          padding-left: 40px;
          p {
            font-size: 18px;
          }
        }
      }
    }
  }
  .characteristics {
    padding: 35px 0;
    background: $color-main-bg;
    @include media-up(md) {
      padding: 100px 0;
    }
    .characteristics-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 5px auto 0;
      @include media-up(sm) {
        margin: 10px -15px 0;
      }
      @include media-up(md) {
        margin: 20px -25px 0;
      }
      .characteristics-content__item {
        width: 100%;
        margin-top: 30px;
        @include media-up(sm) {
          width: 50%;
          padding: 0 15px;
          margin-top: 35px;
        }
        @include media-up(md) {
          width: 33.3%;
          padding: 0 25px;
          margin-top: 45px;
        }
        .icon {
          width: 100px;
          margin: 0 auto;
          @include media-up(md) {
            width: 150px;
          }
        }
        .title {
          margin-top: 15px;
          font-weight: bold;
          font-size: 16px;
          text-align: center;
          span {
            display: inline;
            background: -webkit-gradient(linear, left top, left bottom, color-stop(70%, transparent), color-stop(30%, #F5A13F));
            background: -webkit-linear-gradient(transparent 70%, #F5A13F 30%);
            background: -o-linear-gradient(transparent 70%, #F5A13F 30%);
            background: linear-gradient(transparent 70%, #F5A13F 30%);
          }
          @include media-up(md) {
            margin-top: 25px;
            font-size: 20px;
          }
          @include media-up(lg) {
            font-size: 24px;
          }
        }
        .text {
          margin-top: 15px;
          @include media-up(md) {
            margin-top: 25px;
          }
        }
      }
    }
  }
  .lesson {
    padding: 101px 0 35px;
    margin-top: -66px;
    @include media-up(md) {
      padding: 180px 0 100px;
      margin-top: -80px;
    }
    @include media-up(lg) {
      padding: 100px 0;
      margin-top: 0;
    }
    .lesson-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 10px auto 0;
      @include media-up(md) {
        margin: 95px -20px 0;
      }
      .lesson-content__item {
        width: 100%;
        margin: 30px 0 0;
        border: solid 3px $color-main;
        overflow: hidden;
        @include media-up(md) {
          width: calc(50% - 40px);
          margin: 0 20px;
        }
        .title {
          padding: 12px 0 5px;
          background: $color-main;
          text-align: center;
          font-weight: bold;
          color: $color-white;
          position: relative;
          @include media-up(md) {
            padding: 20px 0 5px;
          }
          &::before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 45vw 0 45vw;
            border-color: $color-main transparent transparent transparent;
            position: absolute;
            bottom: -15px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            @include media-up(md) {
              border-width: 20px 250px 0 250px;
              bottom: -20px;
            }
          }
          .title-text {
            font-size: 16px;
            @include media-up(md) {
              font-size: 24px;
            }
          }
          .title-sub {
            @include media-up(md) {
              line-height: 1.0;
              font-size: 20px;
            }
          }
        }
        .list {
          padding: 25px 5% 25px;
          @include media-up(md) {
            padding: 45px 40px 30px;
          }
          li {
            margin: 10px 0 0;
            padding-left: 1rem;
            text-indent: -1rem;
            font-weight: bold;
            &::before {
              content: "・";
            }
            @include media-up(md) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .tuition {
    padding-top: 66px;
    margin-top: -66px;
    @include media-up(md) {
      padding-top: 80px;
      margin-top: -80px;
    }
    @include media-up(lg) {
      padding-top: 0;
      margin-top: 0;
    }
    .inner {
      padding: 35px 0;
      background: $color-main-bg;
      @include media-up(md) {
        padding: 100px 0;
      }
    }
    .l-container {
      padding: 0;
      @include media-up(md) {
        padding: 0 30px;
      }
    }
    .tuition-text {
      margin: 20px 0 15px;
      text-align: center;
      @include media-up(md) {
        margin: 30px 0 30px;
        font-size: 18px;
        br {
          display: none;
        }
      }
    }
    .tuition-content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      @include media-up(md) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-flow: row-reverse;
        -ms-flex-flow: row-reverse;
        flex-flow: row-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
      }
      @include media-up(lg) {
        font-size: 24px;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
      }
      .tuition-content__text {
        width: 100%;
        padding: 0 5%;
        margin-top: -6px;
        @include media-up(md) {
          width: 290px;
          padding: 0;
          margin-top: 0;
        }
        .item {
          margin-top: 10px;
          border-radius: 5px;
          border: solid 2px $color-main;
          @include media-up(md) {
            margin-top: 20px;
          }
          .title {
            padding: 15px 0;
            line-height: 1.0;
            color: $color-white;
            background: $color-main;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
          .title.popular {
            span {
              position: relative;
              padding-left: 48px;
              &::before {
                content: "";
                display: block;
                width: 50px;
                height: 63px;
                background: url(../img/tuition_icon.png);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                left: -8px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
              }
            }
          }
          .textbody {
            padding: 15px 0;
            background: $color-white;
            border-radius: 0 0 5px 5px;
            .textbody-price {
              text-align: center;
              font-weight: bold;
              font-size: 18px;
              .num {
                font-size: 40px;
                color: $color-main-dark;
                line-height: 1.0;
              }
              .en {
                color: $color-main;
              }
            }
            .textbody-sub {
              text-align: center;
              font-weight: bold;
              margin-top: 5px;
            }
          }
        }
      }
      .tuition-content__image {
        position: relative;
        width: 100%;
        @include media-up(md) {
          width: calc(100% - 290px);
          padding-left: 20px;
        }
        figure {
          padding: 0 5%;
          position: relative;
          z-index: 2;
          max-width: 600px;
          margin: 0 auto;
          @include media-up(md) {
            padding: 0;
            z-index: 0;
            max-width: none;
            margin: 0;
          }
        }
        .caption {
          background: $color-main-dark;
          font-weight: bold;
          color: $color-white;
          text-align: center;
          padding: 12px 0;
          -webkit-transform: translateY(-16px);
          -ms-transform: translateY(-16px);
          transform: translateY(-16px);
          z-index: 1;
          br {
            display: none;
          }
          @include media-up(md) {
            padding: 0;
            width: 175px;
            height: 175px;
            position: absolute;
            border-radius: 50%;
            right: -20px;
            top: -100px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            font-size: 16px;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
            br {
              display: inline;
            }
          }
          @include media-up(lg) {
            width: 215px;
            height: 215px;
            font-size: 18px;
            top: -130px;
          }
        }
      }
    }
    .tuition-attention {
      margin-top: 10px;
      font-size: 12px;
      padding: 0 5%;
      @include media-up(md) {
        margin-top: 16px;
        font-size: 14px;
        padding: 0;
      }
    }
  }
  .experience {
    padding: 101px 0 40px;
    margin-top: -66px;
    @include media-up(md) {
      padding: 180px 0 70px;
      margin-top: -80px;
    }
    @include media-up(lg) {
      padding: 100px 0 70px;
      margin-top: 0;
    }
    .experience-list {
      margin: 10px -10px 0;
      @include media-up(md) {
        margin: 65px -10px 0;
      }
      .experience-list__item {
        padding: 20px 15px;
        background: #FFFBF7;
        -webkit-box-shadow: 0 3px 6px rgba(0,0,0,0.15);
        box-shadow: 0 3px 6px rgba(0,0,0,0.15);
        position: relative;
        margin: 20px 10px;
        @include media-up(md) {
          margin: 30px 10px;
          padding: 40px 40px 30px;
        }
        .inner {
          width: 100%;
          @include media-up(md) {
            width: calc(100% - 235px);
            min-height: 190px;
          }
          .title {
            font-size: 16px;
            padding-left: 28px;
            position: relative;
            @include media-up(md) {
              font-size: 24px;
              padding-left: 50px;
            }
            &::before {
              content: "";
              display: block;
              width: 20px;
              height: 18px;
              background: url(../img/experience_icon.png);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              position: absolute;
              left: 0;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
              @include media-up(md) {
                width: 38px;
                height: 33px;
              }
            }
          }
          .text {
            margin-top: 20px;
            span {
              display: block;
              margin-top: 3px;
            }
            @include media-up(md) {
              margin-top: 25px;
              font-size: 16px;
            }
          }
          .image {
            width: 190px;
            margin: 20px auto 0;
            @include media-up(md) {
              margin: 0;
              position: absolute;
              top: 40px;
            }
          }
        }
        @include media-up(md) {
          &:nth-child(odd) {
            .inner {
              .image {
                right: 40px;
              }
            }
          }
          &:nth-child(even) {
            .inner {
              margin-left: auto;
              .image {
                left: 40px;
              }
            }
          }
        }
      }
    }
  }
  .how {
    padding: 35px 0;
    background: $color-main-bg;
    @include media-up(md) {
      padding: 100px 0;
    }
    .how-list {

      @include media-up(md) {
        margin-top: 85px;
      }
      .how-list__item {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 30px;
        @include media-up(md) {
          margin-top: 25px;
          min-height: 80px;
        }
        .title {
          width: 100%;
          text-align: center;
          padding: 12px 0;
          font-size: 16px;
          position: relative;
          @include media-up(md) {
            width: 45%;
            padding: 0;
            font-size: 24px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            &::before {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 25px 18px 0 18px;
              position: absolute;
              bottom: -25px;
              left: 50%;
              -webkit-transform: translateX(-50%);
              -ms-transform: translateX(-50%);
              transform: translateX(-50%);
            }
          }
        }
        .textbody {
          width: 100%;
          margin-top: 13px;
          @include media-up(md) {
            width: 55%;
            margin-top: 0;
            padding-left: 35px;
          }
          .step {
            font-size: 22px;
            font-family: $font-en;
            color: $color-main;
            line-height: 1.0;
            width: 80px;
            height: 26px;
            padding-top: 4px;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            border: 2px solid $color-main;
            border-right-color: $color-main-bg;
            background: $color-main-bg;
            position: relative;
            &::before {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 14px 0 14px 12px;
              border-color: transparent transparent transparent $color-main;
              position: absolute;
              right: -12px;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
            }
            &::after {
              content: "";
              display: block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 12px 0 12px 10px;
              border-color: transparent transparent transparent $color-main-bg;
              position: absolute;
              right: -9px;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }
          .text {
            margin-top: 10px;
          }
        }
        &:nth-child(odd) {
          .title {
            background: #FACF9C;
            &::before {
              border-color: #FACF9C transparent transparent transparent;
            }
          }
        }
        &:nth-child(even) {
          .title {
            background: #FCE8D0;
            &::before {
              border-color: #FCE8D0 transparent transparent transparent;
            }
          }
        }
        &:last-child {
          .title {
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
  .faq {
    padding: 101px 0 35px;
    margin-top: -66px;
    @include media-up(md) {
      padding: 180px 0 100px;
      margin-top: -80px;
    }
    @include media-up(lg) {
      padding: 100px 0;
      margin-top: 0;
    }
    .faq-list {
      margin-top: 40px;
      @include media-up(md) {
        margin-top: 65px;
      }
      dt {
        background: #FFCD93;
        padding: 10px 40px 10px 5%;
        margin-top: 15px;
        position: relative;
        @include media-up(md) {
          padding: 20px 70px 20px 30px;
          margin-top: 30px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
        }
        .arrow {
          display: block;
          position: absolute;
          right: 15px;
          top: calc(50% - 6px);
          width: 12px;
          height: 12px;
          @include media-up(md) {
            right: 30px;
            width: 20px;
            height: 20px;
          }
          &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: -3px;
            border-top: solid 2px $color-black;
            border-left: solid 2px $color-black;
            -webkit-transform: rotate(-135deg);
            -ms-transform: rotate(-135deg);
            transform: rotate(-135deg);
            -webkit-transition: all 0.15s ease;
            -o-transition: all 0.15s ease;
            transition: all 0.15s ease;
            @include media-up(md) {
              top: -10px;
            }
          }
          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-top: solid 2px $color-black;
            border-left: solid 2px $color-black;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            -webkit-transition: all 0.15s ease;
            -o-transition: all 0.15s ease;
            transition: all 0.15s ease;
            opacity: 0;
          }
        }
        .q {
          display: block;
          line-height: 1.0;
          font-family: $font-en;
          font-size: 22px;
          font-weight: bold;
          padding-bottom: 8px;
          padding-left: 6px;
          position: relative;
          @include media-up(md) {
            font-size: 38px;
            padding-bottom: 0;
            padding-right: 25px;
            padding-left: 0;
            position: absolute;
            left: 30px;
            top: 15px;
          }
          &::before {
            content: "";
            display: block;
            width: 27px;
            height: 0;
            border-top: solid 2px $color-black;
            position: absolute;
            bottom: 0;
            left: 0;
            @include media-up(md) {
              width: 0;
              height: 42px;
              border-top: 0;
              border-left: solid 2px $color-black;
              left: auto;
              bottom: auto;
              right: 0;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }
        }
        .text {
          display: block;
          margin-top: 5px;
          @include media-up(md) {
            margin-top: 0;
            padding-left: 80px;
            font-size: 18px;
          }
        }
        &.open {
          .arrow {
            &::before {
              opacity: 0;
            }
            &::after {
              opacity: 1;
            }
          }
        }
      }
      dd {
        padding: 15px 5%;
        border: solid 1px #F5A13F;
        border-top: #FFCD93;
        display: none;
        @include media-up(md) {
          padding: 25px 30px;
          font-size: 16px;
        }
      }
    }
  }
}
#thanks {
  background: $color-main-bg;
  padding: 40px 0;
  margin-top: 66px;
  @include media-up(md) {
    margin-top: 80px;
    padding: 100px 0 110px;
  }
  @include media-up(lg) {
    margin-top: 0;
  }
  .thanks-lead {
    color: $color-main;
    font-size: 18px;
    text-align: center;
    margin-bottom: 35px;
    font-weight: bold;
    @include media-up(sm) {
      br {
        display: none;
      }
    }
    @include media-up(md) {
      font-size: 24px;
      margin-bottom: 60px;
    }
  }
  .thanks-text {
    margin-top: 25px;
    a {
      text-decoration: underline;
    }
    @include media-up(md) {
      margin-top: 35px;
      a:hover {
        text-decoration: none;
      }
    }
  }
}
#contact {
  background: $color-main-bg;
  margin-top: 66px;
  @include media-up(md) {
    margin-top: 80px;
  }
  @include media-up(lg) {
    margin-top: 0;
  }
  #application {
    margin-top: 66px;
    @include media-up(md) {
      margin-top: 80px;
    }
    @include media-up(lg) {
      margin-top: 0;
    }
  }
}
#application {
  padding: 35px 0;
  background: $color-main-bg;
  @include media-up(md) {
    padding: 100px 0;
  }
  .application-banner {
    max-width: 320px;
    width: 100%;
    margin: 30px auto 0;
    @include media-up(md) {
      max-width: 600px;
      margin: 65px auto 0;
    }
  }
  .application-lead {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0 10px;
    @include media-up(md) {
      font-size: 32px;
      margin: 25px 0 20px;
    }
  }
  .application-sub {
    text-align: center;
    @include media-up(md) {
      font-size: 16px;
      br {
        display: none;
      }
    }
  }
  .application-form {
    margin-top: 15px;
    @include media-up(md) {
      margin-top: 30px;
    }
    dl {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      dt {
        width: 100%;
        margin: 20px 0 10px;
        position: relative;
        @include media-up(md) {
          width: 210px;
          margin: 30px 0 0;
          padding: 15px 0;
          font-size: 16px;
        }
        span {
          color: $color-main;
          font-size: 14px;
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
        .optional {
          display: none !important;
        }
      }
      dd {
        width: 100%;
        .error_blank, .error_format {
          color: $color-main-dark;
        }
        @include media-up(md) {
          width: calc(100% - 210px);
          padding-left: 30px;
          margin-top: 30px;
        }
      }
      input[type="text"], input[type="email"], input[type="tel"] {
        background: $color-white;
        border-radius: 5px;
        border: solid 1px #707070;
        padding: 10px 3%;
        width: 100%;
        &::-webkit-input-placeholder {
          color: #ADADAD;
        }
        &:-ms-input-placeholder {
          color: #ADADAD;
        }
        &::-ms-input-placeholder {
          color: #ADADAD;
        }
        &::placeholder {
          color: #ADADAD;
        }
        @include media-up(md) {
          padding: 15px 20px;
        }
      }
      textarea {
        background: $color-white;
        border-radius: 5px;
        border: solid 1px #707070;
        padding: 10px 3%;
        width: 100%;
        &::-webkit-input-placeholder {
          color: #ADADAD;
        }
        &:-ms-input-placeholder {
          color: #ADADAD;
        }
        &::-ms-input-placeholder {
          color: #ADADAD;
        }
        &::placeholder {
          color: #ADADAD;
        }
        @include media-up(md) {
          padding: 15px 20px;
        }
      }
    }
    #form_submit {
      max-width: 260px;
      width: 100%;
      margin: 30px auto 0;
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6.5px 0 6.5px 14px;
        border-color: transparent transparent transparent $color-white;
        position: absolute;
        right: 25px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
      @include media-up(md) {
        max-width: 440px;
        margin: 70px auto 0;
        &:hover {
          &::before {
            border-color: transparent transparent transparent $color-main;
          }
        }
      }

      input {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        color: $color-white;
        font-size: 15px;
        border-radius: 45px;
        background: $color-main;
        border: solid 1px $color-main;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
        @include media-up(md) {
          height: 75px;
          line-height: 75px;
          font-size: 18px;
          &:hover {
            color: $color-main;
            background: $color-white;
          }
        }
      }
    }
  }
}

// color
$color-white: #fff;
$color-black: #3B3A3A;
$color-gray: #676767; //text main
$color-gray-light: #E1E8ED; //border
$color-gray-ultralight: #EAEAEA; //background
$color-main: #F5A13F;
$color-main-dark: #F5773F;
$color-main-light: #ebe1c5;
$color-main-ultralight: #F8FAFE;
$color-main-bg: #FFF9EF;
$color-pink: #ED3E73;
$color-red: #FF5252;
$color-yellow: #FAD508;
$color-orange: #de9610;

// font-family
$font-sans: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Noto Sans JP", Meiryo, メイリオ, sans-serif;
$font-serif: "Hiragino Mincho ProN","Hiragino Mincho Pro",HGS明朝E,メイリオ,Meiryo,serif;
$font-en: 'Baloo Da 2', cursive;

// breakpoints
$breakpoint-up: (
  'xs': 'screen and (min-width: 425px)',
  'sm': 'screen and (min-width: 554px)',
  'md': 'screen and (min-width: 769px)',
  'lg': 'screen and (min-width: 993px)',
  'xl': 'screen and (min-width: 1261px)',
  'xxl': 'screen and (min-width: 1460px)',
) !default;

$breakpoint-down: (
  'xs': 'screen and (max-width: 424px)',
  'sm': 'screen and (max-width: 553px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 992px)',
  'xl': 'screen and (max-width: 1260px)',
  'xxl': 'screen and (max-width: 1459px)',
) !default;

@mixin media-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin media-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}

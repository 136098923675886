.header {
  height: 66px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: $color-white;
  @include media-up(md) {
    height: 80px;
  }
  @include media-up(lg) {
    position: relative;
    height: 110px;
  }
  .header-logo {
    width: 105px;
    position: absolute;
    left: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    @include media-up(md) {
      width: 125px;
    }
    @include media-up(lg) {
      width: 145px;
    }
    @include media-up(xl) {
      width: 175px;
    }
  }
  .header-nav {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background: $color-main-bg;
    @include media-up(lg) {
      position: absolute;
      right: 5%;
      left: auto;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: auto;
      display: block;
      background: none;
    }
    .header-nav__list {
      margin-top: 51px;
      @include media-up(md) {
        margin-top: 65px;
      }
      @include media-up(lg) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin: 0 -15px;
        height: 100%;
      }
      @include media-up(xl) {
        margin: 0 -25px;
      }
      .item {
        text-align: center;
        height: 100%;
        padding: 15px 0;
        @include media-up(lg) {
          padding: 0;
        }
        a {
          display: block;
          height: 100%;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-flow: column;
          -ms-flex-flow: column;
          flex-flow: column;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          position: relative;
          padding: 0 15px;
          max-width: 250px;
          width: 100%;
          margin: 0 auto;
          @include media-up(lg) {
            max-width: none;
            width: auto;
            margin: 0;
            &:hover {
              &::before {
                opacity: 1;
                width: 100%;
              }
            }
          }
          @include media-up(xl) {
            padding: 0 25px;
          }
          &::before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-top: solid 4px $color-main;
            position: absolute;
            bottom: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            opacity: 0;
            -webkit-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }

          .ja {
            font-size: 15px;
            font-weight: bold;
            @include media-up(xl) {
              font-size: 16px;
            }
          }
          .en {
            font-size: 13px;
            font-family: $font-en;
            color: $color-main;
            font-weight: bold;
          }
        }

      }
    }
  }
  .header-nav.open {
    display: block;
  }
  .menu {
    position: absolute;
    z-index: 2000;
    top: 32px;
    -webkit-transform: translateY(-50%);
           -ms-transform: translateY(-50%);
           transform: translateY(-50%);
    right: 5%;
    display: block;
    width: 30px;
    height: 16px;
    cursor: pointer;

    .menu-line {
      position: absolute;
      width: 30px;
      height: 2px;
      background: $color-black;
      &:nth-of-type(1) {
        top: 0;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
      &:nth-of-type(2) {
        top: 8px;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
      &:nth-of-type(3) {
        top: 16px;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
    }
    @include media-up(md) {
      top: 40px;
    }
    @include media-up(lg) {
     display: none;
    }
  }
  .menu.open {
    .menu-line {
      background: $color-black;
      &:nth-of-type(1) {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 9px;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
      &:nth-of-type(2) {
        opacity: 0;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
      &:nth-of-type(3) {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 9px;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
      }
    }
  }
}

.l-container {
  width: 100%;
  padding: 0 5%;
  margin: 0 auto;
  @include media-up(md) {
    max-width: 1060px;
    padding: 0 30px;
  }
}

.l-container--min {
  width: 100%;
  padding: 0 5%;
  margin: 0 auto;
  @include media-up(md) {
    max-width: 860px;
    padding: 0 30px;
  }
}

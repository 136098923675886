@charset "utf-8";

/* ===================================================================
Theme Name: template-wordpress-typeA
Theme URI:
Description:
Author: FURAZOA inc
Author URI: http://furazoa.com
Version: 1.0.1
=================================================================== */
@import 'vars';
@import 'base';
@import 'ress';
@import 'header';
@import 'footer';
@import 'module';
@import 'layout';
@import 'page';
